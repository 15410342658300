<template>
  <div>
    <div class="top-bar2 flex flex-col justify-between ">
      <div class="account-title">Dash Board</div>
      </div>
      <div class="bottom">
        <div class="sm:mt-0 mt-4 vertify-bot-right ml-0 sm:ml-0px">
          <div class="account_num">账户验证</div>
          <div class="flexBetween alignCenter marginTop20 black45">
              <div class="flex items-center">
                邮箱验证:
              </div>
              <div class=" text-14px"><span class="pink">未认证</span>
              <span class="green">已认证</span>
              <span class="green">正在审核</span>
              </div>
            </div>
            <div class="flexBetween alignCenter marginTop20 black45">
              <div class="flex items-center">
                开户认证:
              </div>
              <div class=" text-14px"><span class="pink">未认证</span>
              <span class="green">已认证</span>
              <span class="green">正在审核</span>
              </div>
            </div>
            <!-- <div class="flexBetween alignCenter marginTop20 black45">
              <div class="flex items-center">
                地址验证:
              </div>
              <div class=" text-14px"><span class="pink">未认证</span>
              <span class="green">已认证</span>
              <span class="green">正在审核</span>
              </div>
            </div> -->
        </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.transition-bot-right {
  width: 480px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 32px;
  box-sizing: border-box;
}
.vertify-bot-right {
  width: 350px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 32px;
  box-sizing: border-box;
}
.account_num {
  width: 80px;
  height: 28px;
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
}
.questionIcon {
  width: 16px;
  height: 16px;
}
.pink{
  color: #FF4D4F;
}
.green{
  color:#67C23A
}
@media only screen and (max-width:600px){

.transition-bot-right {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 32px;
  box-sizing: border-box;
}
.vertify-bot-right {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 32px;
  box-sizing: border-box;
}
.account_num {
  width: 80px;
  height: 28px;
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
}
.questionIcon {
  width: 16px;
  height: 16px;
}
}
</style>
